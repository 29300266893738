export interface AboutOutGoingLink {
  name: string;
  link: string;
  caption: string;
}

export const AboutData = {
  intro: "Hey there, I am Ng Chen Hon.",
  para1:
    "I'm a Senior Software Engineer in Singapore, with 6+ years of experience building scalable systems, architecting solutions, and delivering production services. I specialize backend development in Ruby, PostgreSQL, and AWS.",
  para2:
    "Graduated in Computer Science (Game Development) from Multimedia University, Malaysia. I'd started as software generalist in frontend and React Native apps. Since then, I’ve worked across multiple domains like high-volume data processing, large-scale migrations and database reliability.",
  para3_1: "Now, I work at ",
  para3_link: {
    text: "Ascenda",
    href: "https://www.ascenda.com/",
  },
  para3_2:
    ". I’ve led meaningful projects like the Ruby 3 Upgrade Initiative, releasing a large-scale migration with no production disruptions and a data archival framework, improving database reliability. I enjoy solving complex backend challenges, improving developer experience and collaborating to ship reliable solutions.",
  para4:
    "You can also find me at these places, my username is usually mutatedbread.",
  outGoingLinks: [
    {
      name: "Linkedin",
      link: "https://www.linkedin.com/in/ngchenhon/",
      caption: "for work, 工作, kerja",
    },
    {
      name: "Github",
      link: "https://github.com/MutatedBread",
      caption: "i put some codes here",
    },
    {
      name: "Dev.to",
      link: "https://dev.to/mutatedbread",
      caption: "place for articles",
    },
  ] as AboutOutGoingLink[],
};

// 1. Linkedin
// 2. Github
// 3. Dev.to
